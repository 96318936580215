// Footer.js
import React from 'react';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-tl from-rose-700 to-red-700 flex items-center justify-center p-4 shadow-lg z-50">
            <p className="text-white text-center" style={{fontSize:"12px"}}>© {year} Happy Club. Todos los derechos reservados.</p>
        </div>
    );
};

export default Footer;