import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductCard from "./ProductCard";
import { API } from "../Accesos";
import "./Body.css";

function Body({ setearCarrito }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(API);
        if (response.data && response.data) {
          setProducts(response.data);
        } else {
          setProducts([]);
        }
      } catch (error) {
        console.error("Error al obtener los productos:", error);
      }
    };

    fetchProducts();
  }, []);



  return (
    <>
      
      <div
        className={
          "bg-amber-50 px-1 mt-20 mb-16 gap-1 grid grid-cols-2 lg:grid-cols-3 lg:mx-5 mx-1 items-center justify-center"
        }
      >
        {products.map((product) => (
          <ProductCard
            key={product.nombre}
            product={product}
            setearCarrito={setearCarrito}
          />
        ))}
      </div>
    </>
  );
}

export default Body;
