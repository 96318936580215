import Header from './components/Header';
import Footer from './components/Footer';
import Body from './components/Body';
import './App.css';
import { useState } from 'react';


function App() {
  
  const [carrito, setCarrito] = useState({});
  
  var setearCarrito = (nuevoCarrito) => {
    let updatedCarrito = {...carrito, ...nuevoCarrito};
    for (let producto in updatedCarrito) {
      if (updatedCarrito[producto].total === 0) {
        delete updatedCarrito[producto];
      }
    }
    setCarrito(updatedCarrito);
  }

  return (
    <div className="App">
        <Body setearCarrito={setearCarrito} />
        <Header carrito={carrito} setCarrito={setCarrito}/>
        <Footer />
    </div>
  );
}

export default App;