// Header.js
import React, { useState } from "react";
import logo from "../multimedia/Logo.png";
import logoText from "../multimedia/LogoText.png"; 
import { ReactComponent as Cart3 } from "../multimedia/cart3.svg";
import {numCel} from "../Accesos";

const Header = ({ carrito, setCarrito }) => {
  const [showSummary, setShowSummary] = useState(false);
  const totalSum = Object.values(carrito).reduce(
    (sum, { total }) => sum + total,
    0
  );

  const handlePurchase = () => {
    let message = "Mi pedido es:";
    let totalSum = 0;
    for (let producto in carrito) {
      message += `
      - ${producto}: `;
      for (let sabor in carrito[producto].sabor) {
        message += `
        * ${sabor} : ${carrito[producto].sabor[sabor]} unid`;
      }
      message += ` 
      Sub Total: S/${carrito[producto].total},
      `;
      totalSum += carrito[producto].total;
    }
    message += `
    Total a Cancelar: S/${totalSum}`;
    message = encodeURIComponent(message);
    window.open(`https://wa.me/+51${numCel}?text=${message}`);
  };
  const handleCartClick = () => {
    setShowSummary(true);
  };

  const handleCloseClick = () => {
    setShowSummary(false);
  };

  const handleResetClick = () => {
    setCarrito({});
  };

  const buttonClasses = `bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded shadow-md text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl self-center cursor-pointer transition-all rounded-lg border-blue-600 border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]
    `;

  return (
    <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-rose-700 to-red-700 flex px-2 py-0 justify-between items-center z-50">
      <img src={logo} alt="Logo" className="h-16 lg:h-20 lg:pl-5 pl-2" />
      <img src={logoText} alt="Logo" className="h-24 mx-auto" />
      <div className="relative">
        <div onClick={handleCartClick} className="flex flex-col sm:flex-row justify-center items-center" style={{ cursor: "pointer" }}>
          <div className="relative size-fit">
            <Cart3 className="h-10 text-white pr-2" />

            {Object.keys(carrito).length > 0 && (
              <div className="absolute top-0 right-0 bg-amber-500 rounded-full text-slate-100 text-sm w-6 h-6 flex items-center justify-center">
                {Object.keys(carrito).length}
              </div>
            )}
          </div>
          <div className="lg:pl-2 lg:pr-5 flex ">
            {totalSum > 0 && (
              <div className="lg:px-3 px-1 lg:py-2 rounded text-white lg:text-lg bg-amber-500/50 sm:block text-xs">
                <span className="font-bold lg:text-xl text-xs text-amber-300">Total: </span>S/
                {totalSum}
              </div>
            )}
          </div>
        </div>
          
       
        {showSummary && (
          <div
            className="mt-24 h-[1000px] mx-auto fixed top-0 left-0 right-0 flex items-start justify-center lg:bg-black/40 bg-black/50 backdrop-filter backdrop-blur-lg"
            style={{ fontSize: "0.8rem" }}
          >
            <div
              className="mt-[35px] w-[500px] mx-2 bg-amber-50 p-4 rounded shadow-lg font-roboto"
              style={{ fontSize: "0.7rem" }}
            >
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold my-3 ml-10 text-rose-900">
                  Resumen del Carrito
                </h1>
                <button
                  className={`${buttonClasses} border-none text-1xl bg-rose-600/90 text-white rounded-full`}
                  onClick={handleCloseClick}
                >
                  X
                </button>
              </div>
              <hr className="mb-4" />
              <div className="mx-10 flex flex-col items-start overflow-y-auto h-80">
                {Object.entries(carrito).map(([producto, { sabor, total }]) => (
                  <div key={producto} className="mb-4">
                    <h2 className="text-lg font-semibold text-left text-orange-700">{producto}</h2>
                    <ul className="list-disc list-inside">
                      {Object.entries(sabor).map(([sabor, cantidad]) => (
                        <li key={sabor} className="ml-2 text-left">
                          <span className="font-bold text-amber-600">
                            {sabor}
                          </span>
                          :{" "}
                          <span className="font-bold text-blue-600">
                            {cantidad}
                          </span>{" "}
                          unid.
                        </li>
                      ))}
                    </ul>
                    <p className="block font-bold text-left w-full pl-2">
                      SubTotal: S/{total}
                    </p>
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <h2 className="text-rose-900 text-2xl font-bold mb-4">
                  Total: S/{totalSum}
                </h2>
              </div>
              <hr className="mb-4" />
              <div className="flex justify-between">
                <button
                  style={{ fontSize: "14px" }}
                  className={
                    buttonClasses + "h-50 flex-1 mr-2 py-2 bg-emerald-500 text-white shadow-black/30"
                  }
                  onClick={handlePurchase}
                >
                  Finalizar Compra
                </button>
                <button
                  style={{ fontSize: "14px" }}
                  className={
                    buttonClasses + "h-50 flex-1 mr-2 py-2 bg-rose-600 text-white shadow-black/30"
                  }
                  onClick={handleResetClick}
                >
                  Vaciar Carrito
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
