// ImageSlider.js
import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./ImageSlider.css";


const ImageSlider = ({ images, description }) => {
    const [currentImage, setCurrentImage] = useState(images[0]);
    const [imageIndex, setImageIndex] = useState(0);

   
    useEffect(() => {
        const timer = setInterval(() => {
            setImageIndex((prevIndex) => {
                const newIndex = prevIndex + 1 === images.length ? 0 : prevIndex + 1;
                setCurrentImage(images[newIndex]);
                return newIndex;
            });
        }, 5000);

        return () => clearInterval(timer);
    }, [images]);

    const nextImage = () => {
        setImageIndex((prevIndex) => {
            const newIndex = prevIndex + 1 === images.length ? 0 : prevIndex + 1;
            setCurrentImage(images[newIndex]);
            return newIndex;
        });
    };

    const prevImage = () => {
        setImageIndex((prevIndex) => {
            const newIndex = prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1;
            setCurrentImage(images[newIndex]);
            return newIndex;
        });
    };

    return (
        <div className="relative h-4/5">
            <div className="absolute z-10 bottom-10 left-2 ml-2">
                <button className="buttons"  onClick={prevImage}>&lt;</button>
            </div>
            <div className="absolute z-10 bottom-10 right-2 mr-2">
                <button className="buttons" onClick={nextImage}>&gt;</button>
            </div>
            <div className="relative lg:h-[400px] h-[200px]  overflow-hidden">
                <TransitionGroup>
                    <CSSTransition key={currentImage} timeout={1500} classNames="fade">
                        <img
                            style={{ width: 'calc(100% )',  left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }}
                            className="absolute object-cover w-full lg:h-[400px] h-[200px] transition-opacity duration-500 opacity-100 hover:opacity-100 shadow-lg"
                            src={currentImage}
                            alt="product"
                        />
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <div
          style={{
            fontSize: "10px",
            background: "white",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
        >
          <i>i</i>
        </div>
        <div className="description absolute top-0 left-0 w-full h-full bg-white bg-opacity-80 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-500 text-xs lg:text-lg font-bold italic"       
        >
          <p className="descripcion text-center px-4 ">"{description}"</p>
        </div>
        </div>
    );
};

export default ImageSlider;